import styled from "styled-components";
import {boldGreyBtnColor, boldTextColor, defaultBackgroundColor, lightGreyColor} from "../../../../utils/colors";


export const ContentCardWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 120px;
  
  @media (max-width: 845px) {
    margin-top: 40px;
  }
  
`;

export const ContentCard = styled.div`
  
  padding: 24px;
  position: relative;
  display: flex;
  flex-direction: row;  /* 기본 설정 */
  width: 1100px;
  
  align-items: center;
  align-self: center;
  justify-content: space-between;
  
  opacity: 0;
  transform: translateY(100px); /* 이동 폭을 100px로 설정 */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out; /* 속도를 동일하게 설정 */
  text-align: center;

  margin-bottom: 250px;
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  /* 작은 화면에서의 반응형 처리 */
  @media (max-width: 1110px) {
    flex-direction: column;  /* 화면이 작을 때는 column으로 변경 */
    margin-bottom: 70px;
    /* 이미지가 위로, 텍스트가 아래로 가도록 순서 변경 */
    & > div:nth-child(2) {
      order: -1;  /* ImageWrapper가 첫 번째로 오도록 설정 */
    }
  }
`;



export const TextWrapper = styled.div`
  text-align: start;
  display: flex;
  flex-direction: column;
  
  @media (max-width: 700px) {
    margin-top: 25px;
    
    /* 이미지가 위로, 텍스트가 아래로 가도록 순서 변경 */
    //& > div:nth-child(2) {
    //  order: -1;  /* ImageWrapper가 첫 번째로 오도록 설정 */
    //}
  }
  
`;
export const ImageWrapper = styled.div`
    
`

export const ImageContent = styled.img`
    width: 500px;
  border-radius: 10px;
  @media (max-width: 540px) {
    width: 100%;
  } 
`;

export const IntroTextStyle = styled.div`
  font-size: 20px;
  //color: #A0C1B8;
  font-family: Pretendard-Bold;
  color: ${defaultBackgroundColor};

  @media (max-width: 1110px) {
    margin-top: 25px;
    font-size: 16px;
    
  }
  
`;

export const TitleTextStyle = styled.div`
  font-size: 32px;
  
  font-family: Pretendard-Bold;
  color: black;

  @media (max-width: 1110px) {
    
    font-size: 20px;

  }
`;

export const ExplainTextStyle = styled.div`
  font-size: 16px;
  //color: ${lightGreyColor};
  font-family: Pretendard-Bold;
  color: #7f7f7f;
  @media (max-width: 1110px) {
  
  font-size: 14px;
    
}
`

//D4E7C5
//BFD8AF
//99BC85


//DEECFC
//B1D1C5
//C9DABF

//https://colorhunt.co/palette/f3f8ffdeecffc6cfffe8d3ff
