import styled from "styled-components";
import { boldGreyBtnColor, lightGreyColor } from "../../../utils/colors";

// 반응형 디자인 적용된 Container
export const Container = styled.div`
  background-color: white;
  z-index: 100;
`;

export const InfoWrapper = styled.div`
  
  background-color: white;
  position: relative;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  z-index: 100;

  /* 반응형 디자인 적용 */
  @media (max-width: 1341px) {
    flex-direction: column;  /* 작은 화면에서는 수직으로 배치 */
    padding: 16px;  /* 여백 추가 */
  }
`;

export const BorderTop = styled.div`
  border-bottom: 0.5px solid black;
  width: 100%;
`;

export const InfoContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: end;
  margin: 8px;

  /* 반응형 디자인 적용 */
  @media (max-width: 1341px) {
    flex-direction: column;  /* 작은 화면에서는 수직으로 배치 */
    align-items: center;
  }
`;

export const InfoBannerImg = styled.img`
  width: 500px;

  /* 반응형 디자인 적용 */
  @media (max-width: 1341px) {
    width: 100%;  /* 작은 화면에서는 이미지 크기를 화면에 맞게 조정 */
    margin-bottom: 16px;  /* 이미지와 텍스트 간 간격 추가 */
  }
`;

export const TitleTextStyle = styled.div`
  font-size: 14px;
  font-family: 'Pretendard-Bold', sans-serif;
  color: ${boldGreyBtnColor};
  text-align: center;
  margin: 0 8px;

  /* 반응형 디자인 적용 */
  @media (max-width: 1341px) {
    font-size: 12px;  /* 작은 화면에서 텍스트 크기 축소 */
    margin: 0 4px;  /* 마진 축소 */
  }
`;

export const TextDivider = styled.div`
  font-size: 14px;
  font-family: 'Pretendard-Bold', sans-serif;
  color: ${boldGreyBtnColor};
  text-align: center;
  margin: 0 4px;

  /* 반응형 디자인 적용 */
  @media (max-width: 1341px) {
    display: none;
  }
`;

export const ContentTextStyle = styled.div`
  font-size: 14px;
  font-family: 'Pretendard-Bold', sans-serif;
  color: #7f7f7f;
  text-align: center;

  /* 반응형 디자인 적용 */
  @media (max-width: 1341px) {
    font-size: 12px;  /* 작은 화면에서 텍스트 크기 축소 */
  }
`;
