import React, { useRef } from "react";
import {
  Container,
  ServiceGrid,
  ServiceItem,
  ServiceTitle,
  ServiceSubTitle,
  ServiceTitleText,
  ServiceDescription,
} from "./Styled";
import SizedBox from "../../../components/SizedBox";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

export default function PremisesSector() {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const subtitleRef = useRef<HTMLHeadingElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);

  const isTitleVisible = useIntersectionObserver(titleRef);
  const isSubTitleVisible = useIntersectionObserver(subtitleRef);
  const isGridVisible = useIntersectionObserver(gridRef);

  return (
    <Container id="section2">
      <SizedBox height={200} />
      <ServiceTitle ref={titleRef} className={isTitleVisible ? "visible" : ""}>
        걸어다니기 힘든 우리 시설
      </ServiceTitle>
      <ServiceSubTitle
        ref={subtitleRef}
        className={isSubTitleVisible ? "visible" : ""}
      >
        조금 더 편안하고 여유롭게 이동할 수는 없을까요?
      </ServiceSubTitle>
      <ServiceGrid ref={gridRef} className={isGridVisible ? "visible" : ""}>
        <ServiceItem imageUrl="/images/education_facility.png">
          <ServiceTitleText className="title">교육시설</ServiceTitleText>
          <ServiceDescription className="description">
            학생과 교직원에게 편리하고 올바른 이동을 제공합니다. 건물 간 이동
            시간을 줄이고, 공유모빌리티의 무분별한 주차문제를 해결합니다.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem imageUrl="/images/residential_facility.png">
          <ServiceTitleText className="title">주거시설</ServiceTitleText>
          <ServiceDescription className="description">
            입주민의 편리한 이동을 지원하여 주변 생활 편의 시설로의 접근성을
            높이고, 스마트한 주거환경을 만듭니다.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem imageUrl="/images/industrial_facility.png">
          <ServiceTitleText className="title">소규모 산업시설</ServiceTitleText>
          <ServiceDescription className="description">
            임직원들의 산업단지 내 이동을 효율적으로 돕습니다. 간단한 외출 시
            차량 대신 전기자전거로 빠르게 이동할 수 있어 편의성을 제공합니다.
          </ServiceDescription>
        </ServiceItem>
        <ServiceItem imageUrl="/images/megafactory_facility.png">
          <ServiceTitleText className="title">메가팩토리</ServiceTitleText>
          <ServiceDescription className="description">
            넓은 공장 내부와 외부로의 이동을 혁신합니다. 대규모 시설 내 직원들의
            이동 시간을 단축하고, 생산성을 높이는 동시에 안전하고 효율적인 이동
            수단을 제공합니다.
          </ServiceDescription>
        </ServiceItem>
      </ServiceGrid>
    </Container>
  );
}
