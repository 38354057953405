import React, {useState} from "react";
import {
    Container,
    ContentWrapper,
    TitleTextStyle,
    ButtonWrapper,
    ContentTextStyle,
} from "./Styled";

import SizedBox from "../../../components/SizedBox";
import {GradationBtn} from "../../../components/button/gradationBtn";
import {AdoptionInquiryModal} from "../../../components/modal/AdoptionInquiryModal";

export default function AdoptionInquirySector() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Container id="section6">
            <ContentWrapper>
                <TitleTextStyle>발켄모빌리티와 함께</TitleTextStyle>
                <ContentTextStyle>
                    스마트하고 편리한 우리 시설을 만들고 싶다면?
                </ContentTextStyle>
                <ButtonWrapper>
                    <GradationBtn
                        isBlack={false}
                        width={200}
                        height={50}
                        shadowAlpha={0.3}
                        fontSize={18}
                        onClick={() => setIsModalOpen(true)}
                    >
                        지금 바로 상담받기
                    </GradationBtn>
                </ButtonWrapper>
            </ContentWrapper>

            {isModalOpen && (
                <AdoptionInquiryModal onClose={() => setIsModalOpen(false)}/>
            )}
        </Container>
    );
}
