import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import "./assets/fonts/static/globalStyles.css"
import HomePage from "./pages/home/Home";
import GlobalStyle from "./utils/globalStyles";
import AppBar from "./components/appbar/Appbar";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function App() {
    return (
        <>
            <Router>
                <AppBar/>
                <GlobalStyle/>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                </Routes>
            </Router>
        </>
    );
}
