import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    
    overflow-x: hidden;
    overscroll-behavior: none;
    scroll-behavior: smooth;
  }

  /* 터치 스크롤 및 튕김 현상 방지, 스크롤 이벤트가 필요한 경우에만 적용하지 않도록 수정 */
  body {
    /* 터치 동작 제한을 제거 */
    touch-action: auto;
  }
`;

export default GlobalStyle;
