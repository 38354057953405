import styled from "styled-components";

interface BoxProps {
  backgroundImage: string;
  dataHoverImage: string;
}

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  text-align: left;
  width: 450px;

  @media (max-width: 768px) {
    width: 100%; /* 화면이 좁아지면 전체 너비를 사용 */
    margin-bottom: 20px;
  }
`;

export const Container = styled.div`
  min-height: 130vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CompositionTitle = styled.h1`
  font-size: 20px;
  font-family: "Pretendard-Bold", sans-serif;
  margin-bottom: -20px;
  color: #7f7f7f;
  text-align: center;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 670px) {
    font-size: 18px;
  }

  @media (max-width: 488px) {
    font-size: 16px;
  }

  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

export const CompositionSubTitle = styled.h1`
  font-size: 44px;
  font-family: "Pretendard-Bold", sans-serif;
  color: black;
  text-align: center;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 845px) {
    font-size: 32px;
  }

  @media (max-width: 670px) {
    margin-top: 32px;
    font-size: 24px;
  }

  @media (max-width: 488px) {
    margin-top: 32px;
    font-size: 20px;
  }

  @media (max-width: 420px) {
    margin-top: 32px;
    font-size: 18px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  padding: 0 20px;
  margin-top: 70px;
  justify-items: center;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* 화면이 좁아지면 세로로 정렬 */
  }

  @media (max-width: 845px) {
    margin-top: 40px;
  }
`;

export const Box = styled.div<BoxProps>`
  position: relative;
  width: 450px;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;

  .front,
  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: opacity 0.6s ease;
    border-radius: 10px;
  }

  .front {
    background-image: url(${(props) => props.backgroundImage});
    opacity: 1;
  }

  .back {
    background-image: url(${(props) => props.dataHoverImage});
    opacity: 0;
  }

  &:hover .front {
    opacity: 0;
  }

  &:hover .back {
    opacity: 1;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 400px; /* 작은 화면에서도 이미지 비율 유지 */
  }

  @media (max-width: 480px) {
    height: 300px;
  }
`;

export const BoxSubTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin-top: 20px;
  text-align: left;
  width: 100%;

  @media (max-width: 768px) {
    text-align: left; /* 작은 화면에서는 가운데 정렬 */
  }
`;

export const BoxDescription = styled.div`
  font-size: 14px;
  color: black;
  margin-top: 10px;
  width: 95%;
  word-wrap: break-word;
  max-width: 450px;
  text-align: left; /* 항상 왼쪽 정렬 유지 */

  @media (max-width: 768px) {
    max-width: 100%; /* 작은 화면에서도 최대 너비를 100%로 */
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const ImageButton = styled.a`
  display: inline-block;
  width: 150px;
  height: auto;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;
