import { useState, useEffect } from "react";

// 커스텀 훅: 특정 섹션의 스크롤 감지를 관리
const useSectionObserver = (sectionIds: string[], threshold: number = 0.1 ) => {
    const [activeSection, setActiveSection] = useState<string>("");

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: "0px",
            threshold: threshold, // 기본값으로 섹션이 60% 이상 화면에 보일 때 활성화
        };

        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        sectionIds.forEach((sectionId) => {
            const section = document.getElementById(sectionId);
            if (section) observer.observe(section);
        });

        return () => {
            sectionIds.forEach((sectionId) => {
                const section = document.getElementById(sectionId);
                if (section) observer.unobserve(section);
            });
        };
    }, [sectionIds, threshold]);

    return activeSection;
};

export default useSectionObserver;
