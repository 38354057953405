import React, {useEffect, useState} from "react";
import {boldTextColor} from "../../utils/colors";
import {
    LogoImg,
    AppBarWrapper,
    TextBtnWrapper,
    AppBarContainer, AppBarSectionBtn,
} from "./Styled";
import SizedBox from "../SizedBox";
import useSectionObserver from "../../hooks/useSectionObserver";
import {GradationBtn} from "../button/gradationBtn";
import {BottomFixedBtn} from "../../pages/home/Styled";
import {AdoptionInquiryModal} from "../modal/AdoptionInquiryModal";

const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({behavior: "smooth"});
    }
};

/**
 * @description 워딩 재정의 필요
 * @constructor
 */

export default function AppBar() {
    const sectionIds = [
        "section1",
        "section2",
        "section3",
        "section4",
        "section5",
        "section6",
    ];
    const activeSection = useSectionObserver(sectionIds); // 커스텀 훅 사용

    const [showButton, setShowButton] = useState(false);
    const [scrollYPosition, setScrollYPosition] = useState<number>(0);


    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleScroll = () => {
        setScrollYPosition(window.scrollY);

        setShowButton(window.scrollY > 900 && window.scrollY < 6346);
    };

    useEffect(() => {

        // 스크롤 이벤트 등록
        window.addEventListener("scroll", handleScroll);

        // 컴포넌트가 언마운트될 때 이벤트 제거
        return () => {

            window.removeEventListener("scroll", handleScroll);
        };
    }, []); // 빈 배열로 두면 한 번만 실행됨. 여기를 수정하지 않아도 이벤트 리스너는 유지되어야 함.

    return (
        <AppBarContainer>
            <AppBarWrapper>
                <LogoImg src="/images/banner_black.png" alt="main"/>
                <TextBtnWrapper>
                    {/*<AppBarSectionBtn*/}
                    {/*    fontColor={boldTextColor}*/}
                    {/*    centered={true}*/}
                    {/*    isActive={activeSection === "section1"}*/}
                    {/*    onClick={() => scrollToSection("section1")}*/}
                    {/*>*/}
                    {/*    홈*/}
                    {/*</AppBarSectionBtn>*/}
                    {/*<SizedBox width={40}/>*/}
                    <AppBarSectionBtn
                        fontColor={boldTextColor}
                        centered={true}
                        isActive={activeSection === "section2"}
                        onClick={() => scrollToSection("section2")}
                    >
                        사업분야
                    </AppBarSectionBtn>

                    {/*<AppBarSectionBtn*/}
                    {/*    fontColor={boldTextColor}*/}
                    {/*    centered={true}*/}
                    {/*    isActive={activeSection === "section3"}*/}
                    {/*    onClick={() => scrollToSection("section3")}*/}
                    {/*>*/}
                    {/*    솔루션 장점*/}
                    {/*</AppBarSectionBtn>*/}
                    {/*<SizedBox width={40}/>*/}
                    <AppBarSectionBtn
                        fontColor={boldTextColor}
                        centered={true}
                        isActive={activeSection === "section3"}
                        onClick={() => scrollToSection("section3")}
                    >
                        도입 효과
                    </AppBarSectionBtn>

                    <AppBarSectionBtn
                        fontColor={boldTextColor}
                        centered={true}
                        isActive={activeSection === "section4"}
                        onClick={() => scrollToSection("section4")}
                    >
                        솔루션 구성
                    </AppBarSectionBtn>

                    <AppBarSectionBtn
                        fontColor={boldTextColor}
                        centered={true}
                        isActive={activeSection === "section5"}
                        onClick={() => scrollToSection("section5")}
                    >
                        도입 프로세스
                    </AppBarSectionBtn>
                    {/*<SizedBox width={40}/>*/}
                    {/*<AppBarSectionBtn*/}
                    {/*    fontColor={boldTextColor}*/}
                    {/*    centered={true}*/}
                    {/*    isActive={activeSection === "section6"}*/}
                    {/*    onClick={() => scrollToSection("section6")}*/}
                    {/*>*/}
                    {/*    도입문의*/}
                    {/*</AppBarSectionBtn>*/}
                </TextBtnWrapper>
                <GradationBtn width={140} height={48} shadowAlpha={0.5} fontSize={14} isBlack={true}
                              onClick={() => setIsModalOpen(true)}
                >
                    도입 문의하기
                </GradationBtn>
            </AppBarWrapper>
            {showButton && (
                <BottomFixedBtn>
                    <GradationBtn height={48} width={250} shadowAlpha={0.5} fontSize={20}
                                  isBlack={false}
                                  onClick={() => setIsModalOpen(true)}
                    >
                        도입 문의하기
                    </GradationBtn>
                </BottomFixedBtn>
            )}
            {isModalOpen && (
                <AdoptionInquiryModal onClose={() => setIsModalOpen(false)}/>
            )}
        </AppBarContainer>
    );
}
