import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  @media (max-width: 768px) {
    // padding: 16px;
  }
`;

export const ModalContent = styled.div`
  background: white;
  width: 55%; /* 모달이 화면의 중앙에 정확히 위치 */
  max-height: 70%;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 150px;
  margin-top: 30px;

  @media (max-width: 1024px) {
    width: 70%;
    flex-direction: column; /* 작은 화면에서는 세로로 정렬 */
    max-height: 90%;
  }

  @media (max-width: 768px) {
    width: 90%; /* 작은 화면에서는 더 넓게 사용 */
    max-height: 90%;
    margin-bottom: 100px;
    margin-top: 20px;
  }

  @media (max-width: 480px) {
    width: 85%;
    // max-height: 100%;
    margin-bottom: 50px;
    margin-top: 10px;
  }
`;

export const ModalLeft = styled.div`
  flex: 1;
  background-color: #f7f7f7;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* 이미지가 영역을 넘지 않도록 유지 */
    display: block;
  }

  @media (max-width: 1024px) {
    height: 200px;
  }

  @media (max-width: 768px) {
    height: 180px;
  }
`;

export const ModalRight = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 16px;
  }

  @media (max-width: 480px) {
    padding: 12px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;

    @media (max-width: 768px) {
      padding: 6px;
    }

    @media (max-width: 480px) {
      padding: 5px;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  input {
    margin-right: 10px;
  }

  label {
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }
`;

export const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${(props) => (props.disabled ? "#ddd" : "#007bff")};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 6px;
    font-size: 12px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
`;
