import styled from "styled-components";
import { purpleTextColor } from "../../utils/colors";

type buttonProps = {
    width: number;
    height: number;
    shadowAlpha: number;
    fontSize: number;
    isBlack: boolean;
};

export const GradationBtn = styled.button<buttonProps>`
  border-radius: 10px;
  width: ${(props) => `${props.width}px`}; /* props로 받은 width 적용 */
  height: ${(props) => `${props.height}px`}; /* props로 받은 height 적용 */
  background: ${(props) =>
          props.isBlack
                  ? 'black' // isBlack이 true일 경우 검은색
                  : `linear-gradient(135deg, ${purpleTextColor}, #a06cf1)`}; /* 그라데이션 배경 */
  color: #fff; /* 텍스트 색상을 흰색으로 설정 */
  padding: 10px 20px; /* 상하좌우 패딩 설정 */
  border: none; /* 테두리 없애기 */
  font-size: ${(props) => `${props.fontSize}px`}; /* props로 받은 fontSize 적용 */
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, ${(props) => props.shadowAlpha}); /* props로 받은 shadowAlpha 적용 */
  transition: all 0.3s ease; /* 부드러운 애니메이션 효과 */
  margin: 16px;
  align-self: center;

  &:hover {
    background: ${(props) =>
            props.isBlack
                    ? 'black' // isBlack이 true일 경우 검은색 유지
                    : `linear-gradient(135deg, #a06cf1, ${purpleTextColor})`}; /* 호버 시 그라데이션 반전 */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, ${(props) => props.shadowAlpha * 0.6}); /* 호버 시 그림자 더 강하게 */
    transform: translateY(-3px); /* 살짝 위로 올라가는 애니메이션 */
  }

  &:active {
    background: ${(props) =>
            props.isBlack
                    ? 'black' // isBlack이 true일 경우 검은색 유지
                    : `linear-gradient(135deg, #8a4de0, ${purpleTextColor})`}; /* 클릭 시 더 진한 색상 */
    transform: translateY(0px); /* 클릭 시 원래 위치로 돌아옴 */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, ${(props) => props.shadowAlpha * 0.4}); /* 클릭 시 그림자 복원 */
  }
`;
