import styled from "styled-components";
import { defaultBackgroundColor } from "../../../utils/colors";

interface ServiceItemProps {
  imageUrl: string;
}

export const Container = styled.div`
  /* height: 105vh; 이 부분을 삭제 */
  background-color: white;
  margin-top: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 90px; /* 아래쪽에 여백 추가 */
`;

export const ServiceTitle = styled.h1`
  font-size: 20px;
  font-family: "Pretendard-Bold", sans-serif;
  color: #7f7f7f;
  margin-bottom: -20px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 670px) {
    font-size: 18px;
  }
  @media (max-width: 488px) {
    font-size: 16px;
  }
  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

export const ServiceSubTitle = styled.h1`
  font-size: 44px;
  font-family: "Pretendard-Bold", sans-serif;
  color: black;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  text-align: center; /* 텍스트 가운데 정렬 */
  padding: 0 20px; /* 좌우 여백 추가 */

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  @media (max-width: 845px) {
    font-size: 32px;
  }

  @media (max-width: 670px) {
    margin-top: 32px;
    font-size: 24px;
  }

  @media (max-width: 488px) {
    margin-top: 32px;
    font-size: 20px;
  }
  @media (max-width: 420px) {
    margin-top: 32px;
    font-size: 18px;
  }
`;

export const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 80%;
  margin-top: 70px;
  max-width: 1200px;
  min-height: 500px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 845px) {
    margin-top: 40px;
  }
`;

export const ServiceItem = styled.div<ServiceItemProps>`
  position: relative;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.2s;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;

  &:hover {
    transform: scale(1.05);
  }

  &:hover .title {
    top: 30%;
    transform: translate(-50%, 0);
  }

  &:hover .description {
    opacity: 1;
    top: 45%;
    transform: translate(-50%, 0);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.imageUrl});
    background-size: cover;
    background-position: center;
    filter: brightness(0.4);
    z-index: 1;
  }

  @media (max-width: 768px) {
    height: 400px;
  }

  @media (max-width: 480px) {
    height: 300px;
  }
`;

export const ServiceTitleText = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 28px;
  font-family: "Pretendard-Bold", sans-serif;
  text-align: center;
  z-index: 2;
  transition: transform 0.3s ease, top 0.3s ease;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

export const ServiceDescription = styled.p`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, 0);
  color: white;
  font-size: 16px;
  font-family: "Pretendard-Regular", sans-serif;
  text-align: center;
  z-index: 2;
  transition: transform 0.3s ease, opacity 0.3s ease, top 0.3s ease;
  opacity: 0;
  width: 85%;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 14px;
    top: 55%;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    top: 50%;
  }
`;
