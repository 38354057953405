import styled from "styled-components";
import { defaultBackgroundColor } from "../../../utils/colors";

export const Container = styled.div`
  position: relative;
  height: 50vh;
  width: 104%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  background-image: url("/images/adoption_inquiry_img.png"); /* 원하는 이미지 경로 */
  background-size: cover;
  background-position: center;
  z-index: 0;
`;

export const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 80px;
  @media (max-width: 845px) {
    margin-top: 40px;
  }
`;

export const TitleTextStyle = styled.div`
  font-size: 24px;
  font-family: "Pretendard-Bold", sans-serif;
  color: ${defaultBackgroundColor};
  line-height: 1.5;
  margin-bottom: 4px;
  @media (max-width: 670px) {
    font-size: 18px;
  }
  @media (max-width: 488px) {
    font-size: 16px;
  }
  @media (max-width: 420px) {
    font-size: 14px;
  }
`;
export const ContentTextStyle = styled.div`
  font-size: 48px;
  font-family: "Pretendard-Bold", sans-serif;
  color: ${defaultBackgroundColor};
  line-height: 1.5;
  @media (max-width: 845px) {
    font-size: 32px;
  }

  @media (max-width: 670px) {
    margin-top: 18px;
    font-size: 24px;
  }

  @media (max-width: 488px) {
    margin-top: 14px;
    font-size: 20px;
  }
  @media (max-width: 420px) {
    margin-top: 8px;
    font-size: 18px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  //margin-bottom: 20px;
`;
