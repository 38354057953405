import styled from "styled-components";
import React, {useRef, useState} from "react";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import Slider from "react-slick";
import SizedBox from "../../../components/SizedBox";
import {
    CardContainer,     Container,
    ProcessCircle,
    ProcessLine,
    ProcessText,
    ProcessWrapper,
    ServiceSubTitle,
    ServiceTitle
} from "./Styled";

export default function AdoptionProgressSector() {
    const titleRef = useRef<HTMLHeadingElement>(null);
    const subtitleRef = useRef<HTMLHeadingElement>(null);

    const isTitleVisible = useIntersectionObserver(titleRef);
    const isSubTitleVisible = useIntersectionObserver(subtitleRef);

    const [activeStep, setActiveStep] = useState<number>(0);
    const sliderRef = useRef<Slider | null>(null);
    const handleCircleClick = (index: number) => {
//        console.log(index)

        sliderRef.current?.slickGoTo(index * 2);  // 클릭된 원에 맞는 슬라이드로 이동
        //setActiveStep(tmp / 2);

    };

    const stepTexts = ["도입 문의", "방문 상담", "현장 실사", "솔루션 도입"];
    const sliderSettings = {
        className: "center",
        focusOnSelect: true,
        centerMode: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,

        speed: 500,
        variableWidth: true,  // 기본적으로 variableWidth를 true로 유지
        afterChange: (current: number) => setActiveStep(current),
        swipe: false,  // 모든 환경에서 swipe를 false로 설정
        responsive: [
            {
                breakpoint: 1216,  // 태블릿 환경 (가로 1024px 이하)
                settings: {
                    //centerPadding: "12px",
                    centerMode: true,
                    slidesToShow: 1,
                    variableWidth: false,  // 태블릿에서도 고정 너비 사용
                    swipe: false,  // 태블릿에서 swipe 비활성화
                },
            },
            {
                breakpoint: 1024,  // 태블릿 환경 (가로 1024px 이하)
                settings: {
                    centerPadding: "12px",
                    centerMode: true,
                    slidesToShow: 1,
                    variableWidth: false,  // 태블릿에서도 고정 너비 사용
                    swipe: false,  // 태블릿에서 swipe 비활성화
                },
            },

            {
                breakpoint: 768,  // 모바일 환경 (가로 768px 이하)
                settings: {

                    centerPadding: "12px",
                    slidesToShow: 1,  // 모바일에서 한 번에 한 슬라이드만 표시
                    variableWidth: false,  // 고정된 너비를 사용
                    swipe: false,  // 모바일에서 swipe를 비활성화
                    centerMode: true,  // 모바일에서는 centerMode를 비활성화하여 전체 슬라이드를 크게 보이게
                },
            },
        ],
    };


    return (
        <Container id='section5'>
            <SizedBox height={200}/>
            <ServiceTitle ref={titleRef} className={isTitleVisible ? "visible" : ""}>솔루션 도입 프로세스</ServiceTitle>
            <ServiceSubTitle ref={subtitleRef} className={isSubTitleVisible ? "visible" : ""}>맞춤형 솔루션 도입, 이렇게
                진행됩니다</ServiceSubTitle>
            <ProcessWrapper>
                {stepTexts.map((text, index) => (
                    <React.Fragment key={index}>
                        <ProcessCircle
                            isActive={index <= activeStep / 2}
                            isBike={index === activeStep / 2}
                            data-number={index + 1}
                            onClick={() => {
                                handleCircleClick(index)}}>
                            <ProcessText>{text}</ProcessText>
                        </ProcessCircle>
                        {index < 3 && (
                            <ProcessLine isActive={index < activeStep / 2}/>
                        )}
                    </React.Fragment>
                ))}

            </ProcessWrapper>
            <SizedBox height={100}/>
            {/* react-slick Slider */
            }
            <div className="slider-container">
                <Slider ref={sliderRef} {...sliderSettings} >
                    {/* img 태그를 사용하여 이미지를 표시 */}
                    <CardContainer src='/images/adoption_process1.png' alt="슬라이드 이미지 1"/>
                    <SizedBox width={16}/>
                    <CardContainer src='/images/adoption_process2.png' alt="슬라이드 이미지 1"/>
                    <SizedBox width={16}/>
                    <CardContainer src='/images/adoption_process3.png' alt="슬라이드 이미지 1"/>
                    <SizedBox width={16}/>
                    <CardContainer src='/images/adoption_process4.png' alt="슬라이드 이미지 1"/>

                </Slider>
            </div>

            {/*<SizedBox height={500}/>*/
            }
        </Container>
    );
}
