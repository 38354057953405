import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom"; // ReactDOM 추가
import {
  ModalOverlay,
  ModalContent,
  ModalLeft,
  ModalRight,
  InputWrapper,
  CheckboxWrapper,
  SubmitButton,
  CloseButton,
} from "./Styled";
import { postAdoptionInquiry } from "../../api/AdoptionInquiry/post_adoption_inquiry_api";

interface ModalProps {
  onClose: () => void;
}

export const AdoptionInquiryModal: React.FC<ModalProps> = ({ onClose }) => {
  const [formValues, setFormValues] = useState({
    companyName: "",
    contact: "",
    email: "",
    agreePrivacy: true,
    agreeMarketing: true,
    agreeAll: true,
  });
  const [loading, setLoading] = useState(false); // 로딩 상태 관리
  const [error, setError] = useState<string | null>(null); // 에러 상태 관리
  const [success, setSuccess] = useState(false); // 성공 상태 관리

  useEffect(() => {
    const scrollPosition = window.scrollY;
    const body = document.querySelector("body") as HTMLElement;

    body.style.overflow = "hidden";
    body.style.position = "fixed";
    body.style.width = "100%";
    body.style.top = `-${scrollPosition}px`;

    return () => {
      const storedScrollY = parseInt(body.style.top || "0") * -1;

      document.documentElement.style.scrollBehavior = "auto";

      body.style.removeProperty("overflow");
      body.style.removeProperty("position");
      body.style.removeProperty("top");
      body.style.removeProperty("width");

      window.scroll(0, storedScrollY);

      document.documentElement.style.removeProperty("scroll-behavior");
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;

    if (name === "agreeAll") {
      setFormValues({
        ...formValues,
        agreePrivacy: checked,
        agreeMarketing: checked,
        agreeAll: checked,
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: type === "checkbox" ? checked : value,
        agreeAll:
            formValues.agreePrivacy && formValues.agreeMarketing && checked,
      });
    }
  };

  const isFormValid = () => {
    return (
        formValues.companyName &&
        formValues.contact &&
        formValues.email &&
        formValues.agreePrivacy &&
        formValues.agreeMarketing
    );
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }

    setLoading(true);
    setError(null);

    const data = {
      companyName: formValues.companyName,
      phoneNumber: formValues.contact,
      email: formValues.email,
    };

    try {
      await postAdoptionInquiry(data);
      setSuccess(true);
      onClose();
    } catch (err) {
      setError("오류가 발생하였습니다. 다시 시도해주세요");
    } finally {
      setLoading(false);
    }
  };

  // createPortal로 렌더링할 모달 내용
  const modalContent = (
      <ModalOverlay onClick={handleOverlayClick}>
        <ModalContent>
          <CloseButton onClick={onClose}>×</CloseButton>
          <ModalLeft>
            <img src="/images/inquiry_modal.png" alt="image" />
          </ModalLeft>
          <ModalRight>
            <form onSubmit={handleSubmit}>
              <InputWrapper>
                <label>기업명 *</label>
                <input
                    type="text"
                    name="companyName"
                    value={formValues.companyName}
                    onChange={handleInputChange}
                    required
                />
              </InputWrapper>
              <InputWrapper>
                <label>연락처 *</label>
                <input
                    type="text"
                    name="contact"
                    value={formValues.contact}
                    onChange={handleInputChange}
                    required
                />
              </InputWrapper>
              <InputWrapper>
                <label>이메일 *</label>
                <input
                    type="email"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    required
                />
              </InputWrapper>

              <CheckboxWrapper>
                <input
                    type="checkbox"
                    name="agreePrivacy"
                    checked={formValues.agreePrivacy}
                    onChange={handleInputChange}
                    required
                />
                <label>
                  개인정보 처리방침{" "}
                  <a href="/privacy" target="_blank" rel="noopener noreferrer">
                    전문보기
                  </a>
                </label>
              </CheckboxWrapper>
              <CheckboxWrapper>
                <input
                    type="checkbox"
                    name="agreeMarketing"
                    checked={formValues.agreeMarketing}
                    onChange={handleInputChange}
                    required
                />
                <label>
                  마케팅 활용 동의{" "}
                  <a href="/marketing" target="_blank" rel="noopener noreferrer">
                    전문보기
                  </a>
                </label>
              </CheckboxWrapper>

              <CheckboxWrapper>
                <input
                    type="checkbox"
                    name="agreeAll"
                    checked={formValues.agreeAll}
                    onChange={handleInputChange}
                />
                <label>모두 동의합니다.</label>
              </CheckboxWrapper>

              {error && <p style={{ color: "red" }}>{error}</p>}

              <SubmitButton type="submit" disabled={!isFormValid() || loading}>
                {loading ? "제출 중..." : "제출하기"}
              </SubmitButton>
            </form>
          </ModalRight>
        </ModalContent>
      </ModalOverlay>
  );

  // createPortal로 모달을 body 하위에 렌더링
  return ReactDOM.createPortal(modalContent, document.body);
};
