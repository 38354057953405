import axios from "axios";

interface InquiryData {
  companyName: string;
  phoneNumber: string;
  email: string;
}

export const postAdoptionInquiry = async (data: InquiryData) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
  console.log(apiUrl);
  try {
    const response = await axios.post(
        `${apiUrl}/balkenM/adoption-mail`,
        data
    );
    return response.data;
  } catch (error) {
    console.error("Error submitting inquiry:", error);
    throw error;
  }
};
