import styled from "styled-components";
import {boldGreyBtnColor, defaultBackgroundColor, purpleTextColor} from "../../../utils/colors";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Container = styled.div`
  height: 1500px;
  background-color: white;
  @media (max-width: 1313px) {

    height: 1300px;
  }
  @media (max-width: 900px) {

    height: 1200px;
  }
  @media (max-width: 700px) {

    height: 1100px;
  }
  @media (max-width: 550px) {

    height: 900px;
  }
`;

export const ServiceTitle = styled.h1`
  font-size: 20px;
  font-family: 'Pretendard-Bold', sans-serif;
  color: #7f7f7f;
  margin-bottom: -20px;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  text-align: center;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 670px) {
    font-size: 18px;
  }
  @media (max-width: 488px) {
    font-size: 16px;
  }
  @media (max-width: 420px) {

    font-size: 14px;
  }
`;

export const ServiceSubTitle = styled.h1`
  font-size: 44px;
  font-family: "Pretendard-Bold", sans-serif;
  color: black;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  text-align: center;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  @media (max-width: 845px) {
    font-size: 32px;
  }

  @media (max-width: 670px) {
    margin-top: 32px;
    font-size: 24px;
  }

  @media (max-width: 488px) {
    margin-top: 32px;
    font-size: 20px;
  }
  @media (max-width: 420px) {
    margin-top: 32px;
    font-size: 18px;
  }

`;

export const ProcessWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  margin: 80px auto;
  position: relative;
  
  @media (max-width: 845px) {
    margin: 40px auto;
  }

  @media (max-width: 768px) {
    width: 90vw;  // 모바일 기기에서는 화면의 90% 너비로 설정
    justify-content: space-around;  // 모바일에서 좀 더 균일한 간격을 위해 사용
  }

  @media (max-width: 485px) {
    width: 85%;  // 작은 모바일 기기에서는 화면의 95% 너비로 설정
    justify-content: space-evenly;  // 요소들 간의 간격을 균등하게 조정
  }
`;

export const ProcessCircle = styled.div<{ isActive: boolean; isBike: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${purpleTextColor};
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? purpleTextColor : "#e5e6eb")};  /* 클릭된 원은 검정색, 나머지는 회색 */
  color: ${({ isActive }) => (isActive ? "#fff" : "#a9adb6")};  /* 클릭된 원 텍스트는 흰색 */
  font-size: 20px;
  font-family: Pretendard-Bold;
  position: relative;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;

  /* 클릭한 원에 bike 이미지를 배경으로 추가 */
  ${({ isBike }) =>
      isBike &&
      `
     background-color : white;
    background-image: url('/images/bike.png');
    background-size: cover;
    background-position: center;
  `}

  &:hover {
    background-color: #000;
    color: #fff;  /* 숫자도 흰색으로 변경 */

    &::before {
      color: #fff;  /* 숫자도 흰색으로 변경 */
    }
  }

  /* 숫자를 이미지 위에 겹치게 */
  &::before {
    content: ${({ isBike }) => (isBike ? "''" : "attr(data-number)")};  /* 클릭되지 않은 원은 숫자 표시 */
    position: absolute;
    color: ${({ isActive }) => (isActive ? "#fff" : "#a9aeb8")};  /* 비활성화 원은 기본적으로 검정색 */
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 485px) {
    width: 30px;
    height: 30px;
  }


`;


export const ProcessLine = styled.div<{ isActive: boolean }>`
  width: 120px;
  height: 2px;
  background-color: ${({isActive}) => (isActive ? purpleTextColor : "#e5e5ea")}; /* 클릭된 원 이전의 선은 검정색 */
`;

export const ProcessText = styled.div`
  position: absolute;
  font-family: Pretendard-Bold;
  font-size: 20px;  // 기본 텍스트 크기
  top: 60px;
  width: 100px;
  text-align: center;
  color: black;  /* 기본 텍스트 색상 */
  transition: color 0.5s ease-in-out;  /* 부드러운 전환 효과 */
  @media (max-width: 768px) {
    font-size: 16px;  // 모바일 환경에서 텍스트 크기를 16px로 축소
  }

  @media (max-width: 485px) {
    font-size: 12px;  // 작은 모바일 기기에서 텍스트 크기를 14px로 더 축소
  }
`;


/**
 *  비활성 회색 #e5e5ea
 *  비활성 택스트 #a9adb6
 *  확인한 라인, 원, black,
 *  확인한 원 숫자 white
 */
// export const CardContainerWrapper = styled.div`
//   width: 100%;
//   //overflow: hidden;
//   display: flex;
//   justify-content: center;
//   padding: 0 50px;  /* 좌우 여백을 넉넉하게 설정 */
// `;

export const CardContainer = styled.img`
  width: 100%;
  max-width: 1200px;
  height: auto;
  object-fit: cover;
  border-radius: 24px;
  border: 0.5px solid grey;

  @media (min-width: 768px) {
    width: 700px;
  }

  @media (min-width: 1024px) {
    width: 900px;
  }
`;
