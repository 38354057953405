import styled from "styled-components";
import {defaultBackgroundColor, purpleTextColor} from "../../../utils/colors";

export const Container = styled.div`
  //height: 2500px;
    //background-color: #FAF7F0;
  //background-color: #4C2882;
  background-color: white;
  //background-color: ${purpleTextColor};
    
  
`;

export const ServiceTitle = styled.h1`
  font-size: 20px;
  font-family: "Pretendard-Bold", sans-serif;
  margin-bottom: -20px;
  color: #7f7f7f;
  text-align: center;
  opacity: 0;
  transform: translateY(100px); /* 동일한 이동 폭 적용 */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out; /* 동일한 속도 적용 */
  
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 670px) {
    font-size: 18px;
  }
  @media (max-width: 488px) {
    font-size: 16px;
  }
  @media (max-width: 420px) {

    font-size: 14px;
  }
  
`;

export const ServiceSubTitle = styled.h1`
  font-size: 44px;
  font-family: "Pretendard-Bold", sans-serif;
  color: black;
  text-align: center;
  opacity: 0;
  transform: translateY(100px); /* 동일한 이동 폭 적용 */
  transition: opacity 0.3s ease-out, transform 0.3s ease-out; /* 동일한 속도 적용 */

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }

  @media (max-width: 845px) {
    font-size: 32px;
  }

  @media (max-width: 670px) {
    margin-top: 32px;
    font-size: 24px;
  }

  @media (max-width: 488px) {
    margin-top: 32px;
    font-size: 20px;
  }
  @media (max-width: 420px) {
    margin-top: 32px;
    font-size: 18px;
  }
  
`;
