import React, {useRef} from 'react';
import {
    ContentCard,
    ImageContent,
    TextWrapper,
    IntroTextStyle,
    ImageWrapper,
    TitleTextStyle,
    ExplainTextStyle, ContentCardWrapper
} from "./Styled";
import SizedBox from "../../../../components/SizedBox";
import useIntersectionObserver from "../../../../hooks/useIntersectionObserver";

type ContentCardProps = {
    introText: string;
    titleText1: string;
    titleText2: string;
    explainText1: string;
    explainText2: string;
    imgSrc: string;
    introTextColor: string;
    containerCardColor: string;
}

export default function ContentComponents({
                                              introText,
                                              titleText1,
                                              titleText2,
                                              explainText1,
                                              explainText2,
                                              imgSrc,
                                              introTextColor,
                                              containerCardColor,
                                          }: ContentCardProps) {

    const firstCard = useRef<HTMLHeadingElement>(null);
    const isFirstVisible = useIntersectionObserver(firstCard);

    return (
        <ContentCardWrapper>
            <ContentCard ref={firstCard} className={isFirstVisible ? "visible" : ""}>
                <TextWrapper>
                    <IntroTextStyle style={{color: introTextColor}}>{introText}</IntroTextStyle>
                    <SizedBox height={16}/>
                    <TitleTextStyle style={{  whiteSpace: 'pre-line' }}>
                        {titleText1}
                    </TitleTextStyle>

                    <TitleTextStyle>{titleText2}</TitleTextStyle>
                    <SizedBox height={32}/>
                    <ExplainTextStyle>{explainText1}</ExplainTextStyle>
                    <SizedBox height={8}/>
                    <ExplainTextStyle>{explainText2}</ExplainTextStyle>
                </TextWrapper>

                <ImageWrapper>
                    <ImageContent src={imgSrc}/>
                </ImageWrapper>
            </ContentCard>
        </ContentCardWrapper>
    );
}
