import React, {useRef} from "react";
import {Container, ServiceSubTitle, ServiceTitle} from "./Styled";
import SizedBox from "../../../components/SizedBox";
import ContentComponents from "./components/ContentComponents";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

/*
    1) 이동복지

    2) 운영 자동화
        1. 충전
        2. 재배치
    3) 주차 문제

 */
export default function AdvantageSector() {
    const titleRef = useRef<HTMLHeadingElement>(null);
    const subtitleRef = useRef<HTMLHeadingElement>(null);

    const isTitleVisible = useIntersectionObserver(titleRef);
    const isSubTitleVisible = useIntersectionObserver(subtitleRef);

    return (
        <Container id='section3'>
            <SizedBox height={200}/>
            <ServiceTitle ref={titleRef} className={isTitleVisible ? "visible" : ""}> 발켄모빌리티와 함께
            </ServiceTitle>
            <ServiceSubTitle
                ref={subtitleRef}
                className={isSubTitleVisible ? "visible" : ""}
            >이동 편의 솔루션 도입 효과</ServiceSubTitle>
            <ContentComponents containerCardColor={"#000000"}
                               introTextColor={"#A594F9"}
                               imgSrc='images/test1.png'
                               introText={"불필요한 도보 이동은 그만"}
                               titleText1={"구성원의 만족을 높이는"}
                               titleText2={"스마트한 이동 솔루션"}
                               explainText1={"빠르고 효율적인 전기자전거로 이동 시간을 단축시켜,"}
                               explainText2={"일상 속에서 더 많은 여유를 누릴 수 있게 합니다."}
            />

            <ContentComponents containerCardColor={"#DEECFF"}
                               introTextColor={"#A594F9"}
                               imgSrc='images/test2.jpeg'
                               introText={"자동화된 충전 시스템\n"}
                               titleText1={"관리 효율 UP"}
                               titleText2={"운영 부담 DOWN"}
                               explainText1={"자동 충전 시스템으로 운영 효율을 극대화하고, "}
                               explainText2={"구성원 이동 동선을 분석하여 최적의 위치에 충전스테이션을 배치합니다."}

            />

            <ContentComponents containerCardColor={"#E8D3FF"}
                               introTextColor={"#A594F9"}
                               imgSrc='images/test3.png'
                               introText={"정돈된 시설 환경 유지"}
                               titleText1={"무분별한 공유모빌리티 주차문제"}
                               titleText2={"스마트하게 해결"}
                               explainText1={"무분별한 공유모빌리티 주차 문제를 해결하는 발켄모빌리티의 충전스테이션 시스템은,"}
                               explainText2={"지정된 구역에만 자전거를 반납하도록 유도하여 깔끔한 시설 환경을 유지합니다."}
            />

        </Container>
    )
}
