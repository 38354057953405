import React, { useRef } from "react";
import {
  Container,
  CompositionTitle,
  ContentWrapper,
  BoxWrapper,
  Box,
  BoxSubTitle,
  BoxDescription,
  CompositionSubTitle,
  ButtonContainer,
  ImageButton,
} from "./Styled";
import SizedBox from "../../../components/SizedBox";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";

export default function CompositionSector() {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const subTitleRef = useRef<HTMLHeadingElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const isTitleVisible = useIntersectionObserver(titleRef);
  const isSubTitleVisible = useIntersectionObserver(subTitleRef);
  const isContentVisible = useIntersectionObserver(contentWrapperRef);

  return (
    <Container id="section4">
      <SizedBox height={80} />
      <CompositionTitle
        ref={titleRef}
        className={isTitleVisible ? "visible" : ""}
      >
        우리 시설 맞춤형 솔루션과 함께
      </CompositionTitle>
      <CompositionSubTitle
        ref={subTitleRef}
        className={isSubTitleVisible ? "visible" : ""}
      >
        효율적인 이동 관리와 운영 자동화를 실현하세요
      </CompositionSubTitle>
      <ContentWrapper
        ref={contentWrapperRef}
        className={isContentVisible ? "visible" : ""}
      >
        {/* Box 1 */}
        <BoxWrapper>
          <Box
            backgroundImage="/images/pas_bike.png"
            dataHoverImage="/images/pas_bike_hover.png"
          >
            <div className="front"></div>
            <div className="back"></div>
          </Box>
          <BoxSubTitle>PAS 전기자전거</BoxSubTitle>
          <BoxDescription>
            발켄모빌리티의 전기자전거는 48V 모터와 내장형 IoT 단말기로,
            편안하면서도 안정적인 이동을 제공합니다. 경사로에서도 탁월한 성능을
            발휘하며, 무소음으로 부드러운 주행이 가능합니다. 사용자 편의를
            극대화한 솔리드 타이어와 소프트 안장이 장착되어 있어 장시간 이용에도
            불편함이 없습니다.
          </BoxDescription>
        </BoxWrapper>

        {/* Box 2 */}
        <BoxWrapper>
          <Box
            backgroundImage="/images/station.png"
            dataHoverImage="/images/station_hover.png"
          >
            <div className="front"></div>
            <div className="back"></div>
          </Box>
          <BoxSubTitle>충전스테이션</BoxSubTitle>
          <BoxDescription>
            발켄모빌리티의 충전스테이션은 무선 접촉식 충전 방식으로, 최대 99%의
            충전 효율을 자랑합니다. 도난 방지를 위한 자동 잠금 기능과 IoT 기술이
            내장되어, 자전거 관리와 충전을 자동화합니다. 주차 문제를 해결하고,
            운영의 효율성을 극대화할 수 있습니다.
          </BoxDescription>
        </BoxWrapper>

        {/* Box 3 (애플리케이션 플랫폼) */}
        <BoxWrapper>
          <Box
            backgroundImage="/images/application_platform.png"
            dataHoverImage="/images/application_platform_hover.png"
          >
            <div className="front"></div>
            <div className="back"></div>
          </Box>
          <BoxSubTitle>애플리케이션 플랫폼</BoxSubTitle>
          <BoxDescription>
            발켄M 앱을 통해 사용자는 자전거 대여, 반납, 일시잠금, 주차 위치
            안내까지 편리하게 이용할 수 있습니다. 실시간 위치 추적과 간편한 결제
            시스템을 통해 편리한 사용자 경험을 제공합니다. 시설 인증 기능을 통해
            구성원만 자전거 및 시설 인프라를 사용할 수 있도록 합니다.
          </BoxDescription>

          {/* 이미지 버튼 추가 */}
          <ButtonContainer>
            <ImageButton
              href="https://apps.apple.com/kr/app/발켄m/id6450990490"
              target="_blank"
            >
              <img src="/images/appstore_img.png" alt="App Store" />
            </ImageButton>
            <ImageButton
              href="https://play.google.com/store/search?q=발켄m&c=apps&hl=ko"
              target="_blank"
            >
              <img src="/images/googleplay_img.png" alt="Google Play Store" />
            </ImageButton>
          </ButtonContainer>
        </BoxWrapper>

        {/* Box 4 */}
        <BoxWrapper>
          <Box
            backgroundImage="/images/admin_platform.png"
            dataHoverImage="/images/admin_platform_hover.png"
          >
            <div className="front"></div>
            <div className="back"></div>
          </Box>
          <BoxSubTitle>어드민 플랫폼</BoxSubTitle>
          <BoxDescription>
            발켄모빌리티의 어드민 플랫폼은 시설이 자전거와 충전 스테이션을
            효과적으로 운영하고 모니터링할 수 있는 통합 관리 시스템을
            제공합니다. 사용자 데이터 분석, 자전거 상태 확인, 이용 기록 관리 등
            운영 자동화를 위한 필수 기능을 갖추고 있어, 더욱 효율적인 운영이
            가능합니다.
          </BoxDescription>
        </BoxWrapper>
      </ContentWrapper>
    </Container>
  );
}
