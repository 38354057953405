// hooks/useIntersectionObserver.ts
import { useEffect, useState, RefObject } from "react";

const useIntersectionObserver = (
  ref: RefObject<HTMLElement>,
  threshold: number = 0.1
): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observerOptions = {
      threshold, // 사용자 지정 임계값
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];
      setIsVisible(entry.isIntersecting);
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, threshold]);

  return isVisible;
};

export default useIntersectionObserver;
