import React, {useEffect, useState} from 'react';
import {
    BackGroundImageText,
    BackgroundImg,
    Container,
    SubTitleTextStyle,
    TitleTextStyle,
    MaskWrapper,
    GradationBtn,
    MiddleTitleTextStyle, BottomFixedBtn,
} from "./Styled";
import SizedBox from "../../../components/SizedBox";
import {AdoptionInquiryModal} from "../../../components/modal/AdoptionInquiryModal";



// 4개의 텍스트 리스트
const textList = ["학생을 위한", "임직원을 위한", "입주민을 위한", "구성원을 위한"];

export default function MainSector() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % textList.length); // 원형 큐처럼 인덱스 순환
        }, 2500);

        return () => clearInterval(interval);
    }, []);

    const nextIndex = (currentIndex + 1) % textList.length; // 다음 텍스트 인덱스
    const [isModalOpen, setIsModalOpen] = useState(false);
    return (
        <Container id='section1'>

            <BackgroundImg src='/images/main_background.png' alt="main"/>
            <SizedBox height={400}/>
            <BackGroundImageText>
                <MiddleTitleTextStyle>발켄모빌리티</MiddleTitleTextStyle>
                <SizedBox height={12}/>
                <MaskWrapper>
                    <SubTitleTextStyle isCurrent={true} key={currentIndex}>
                        {textList[currentIndex]}
                    </SubTitleTextStyle>
                    <SubTitleTextStyle isCurrent={false} key={nextIndex}>
                        {textList[nextIndex]}
                    </SubTitleTextStyle>
                </MaskWrapper>
                <TitleTextStyle>이동 편의 토탈 솔루션</TitleTextStyle>
                <SizedBox height={64}/>
                <BottomFixedBtn>
                    <GradationBtn onClick={() => setIsModalOpen(true)}>
                        도입 문의하기
                    </GradationBtn>
                </BottomFixedBtn>

            </BackGroundImageText>
            {isModalOpen && (
                <AdoptionInquiryModal onClose={() => setIsModalOpen(false)}/>
            )}
        </Container>
    );
}
