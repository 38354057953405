import styled from "styled-components";
import {boldTextColor, purpleTextColor} from "../../utils/colors";

export const BottomFixedBtn = styled.div`
  position: fixed;
  display: flex;
  z-index: 13;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  
  // border-radius: 24px;
  // flex-direction: row;
  // //border: 1px solid black;
  //   //background-color: ${purpleTextColor};
  // padding: 8px 12px;
  // background-color: white;
  //box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* 입체감 있는 그림자 */
`


export const LogoImg = styled.img`
  height: 100%;
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.3); /* 입체감 있는 그림자 */
`;

