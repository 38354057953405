import React from "react";
import {
    BorderTop,
    Container,
    ContentTextStyle,
    InfoBannerImg,
    InfoContainer,
    InfoWrapper, TextDivider,
    TitleTextStyle
} from "./Styled";
import SizedBox from "../../../components/SizedBox";

export default function CompanyInfoSector() {
    return (
        <Container id='section7'>

            <BorderTop></BorderTop>

            <InfoWrapper>
                <SizedBox height={100}/>
                <InfoContainer>
                    <TitleTextStyle>Company </TitleTextStyle>
                    <TitleTextStyle> 주식회사 발켄모빌리티</TitleTextStyle>
                </InfoContainer>
                <SizedBox height={8}/>
                <TextDivider>|</TextDivider>
                <InfoContainer>
                    <TitleTextStyle>Address </TitleTextStyle>
                    <TitleTextStyle> 경기도 화성시 동탄순환대로3가길 14-2 1층</TitleTextStyle>
                </InfoContainer>
                <SizedBox height={8}/>
                <TextDivider>|</TextDivider>
                <InfoContainer>
                    <TitleTextStyle>Tel </TitleTextStyle>
                    <TitleTextStyle> 070-7716-2586</TitleTextStyle>
                </InfoContainer>
                <SizedBox height={8}/>
                <TextDivider>|</TextDivider>
                <InfoContainer>
                    <TitleTextStyle>Business License </TitleTextStyle>
                    <TitleTextStyle> 489-87-01654</TitleTextStyle>
                </InfoContainer>
                <SizedBox height={8}/>
                <TextDivider>|</TextDivider>
                <InfoContainer>
                    <TitleTextStyle>Online License </TitleTextStyle>
                    <TitleTextStyle> 2020-화성동탄-1989</TitleTextStyle>
                </InfoContainer>
                <SizedBox height={100}/>
            </InfoWrapper>

        </Container>
    )
}
