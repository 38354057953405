import React, { useEffect, useState } from "react";
import { BottomFixedBtn } from "./Styled";
import { GradationBtn } from "../../components/button/gradationBtn";
import MainSector from "./main_sector/MainSector";
import PremisesSector from "./premises_sector/PremisesSector";
import AdvantageSector from "./advantage_sector/AdvantageSector";
import CompositionSector from "./composition_sector/CompositionSector";
import AdoptionProgressSector from "./adoption_progress_sector/AdoptionProgressSector";
import AdoptionInquirySector from "./adoption_inquiry_sector/AdoptionInquirySector";
import CompanyInfoSector from "./company_info_sector/CompanyInfoSector";

export default function HomePage() {

    return (
        <>
            <MainSector />
            <PremisesSector />
            <AdvantageSector />
            <CompositionSector />
            <AdoptionProgressSector />
            <AdoptionInquirySector />
            <CompanyInfoSector />

        </>
    );
}
