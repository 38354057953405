import styled from "styled-components";
import {
    boldTextColor, defaultBackgroundColor,
    defaultContainerColor,
    lightBlueTextColor,
    purpleTextColor
} from "../../../utils/colors";
import {keyframes} from "styled-components";

export const TitleTextStyle = styled.div`
  font-size: 55px;
  font-family: 'Pretendard-Bold', sans-serif; ;
  //font-weight: bold;
  color: ${defaultBackgroundColor};
  text-align: center;
  @media (max-width: 520px) {
    font-size: 40px;  // 모바일 환경에서 텍스트 크기를 16px로 축소
  }
`;

export const MiddleTitleTextStyle = styled.div`
  font-size: 28px;
  font-family: 'Pretendard-Bold', sans-serif; ;
  //font-weight: bold;
  color: ${defaultBackgroundColor};
  text-align: center;
  @media (max-width: 520px) {
    font-size: 20px;  // 모바일 환경에서 텍스트 크기를 16px로 축소
  }
`;

// 위로 올라가는 애니메이션 (현재 텍스트)
const slideOut = keyframes`
  0% {
    transform: translateY(0); // 중앙에서 시작
  }
  100% {
    transform: translateY(-100%); // 위로 사라짐
  }
`;

// 아래에서 올라오는 애니메이션 (다음 텍스트)
const slideIn = keyframes`
  0% {
    transform: translateY(100%); // 아래에서 시작
  }
  100% {
    transform: translateY(0); // 중앙으로 이동
  }
`;

export const SubTitleTextStyle = styled.div<{isCurrent: boolean}>`
  font-size: 55px;
  font-family: "Pretendard-Bold";
  font-weight: normal;
  text-align: start;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: ${purpleTextColor};
  //color: ${defaultBackgroundColor};
  animation: ${({isCurrent}) => (isCurrent ? slideOut : slideIn)} 2.5s ease-in-out;
  @media (max-width: 520px) {
    font-size: 40px;  // 모바일 환경에서 텍스트 크기를 16px로 축소
  }
`;

export const MaskWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 70px; // 텍스트가 보일 영역
  overflow: hidden; // 이 영역을 벗어난 텍스트는 보이지 않음
  display: flex;
  justify-content: start;
  align-items: start;
  align-self: start;
  @media (max-width: 520px) {
    height: 45px;  // 모바일 환경에서 텍스트 크기를 16px로 축소
  }
`;
export const Container = styled.div`
  position: relative;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  //align-self: center;
  min-height: 100vh;

  overscroll-behavior: none;
  touch-action: pan-y;
  margin-bottom: 0;
  
  
`;

export const BackgroundImg = styled.img`
;
  width: 102%;
  height: 100%; 
  object-fit: cover;
  position: fixed; 
  top: 0;
  left: -1%;
  z-index: -1;
`;

export const BackGroundImageText = styled.div`
  position: relative;
  
  align-items: start;
  display: flex;
  flex-direction: column;
  align-self: start;
  
  z-index: 10;
  top: 45%;
  left: 30%;
  transform: translate(-30%,-50%); 
`
export const BannerImg = styled.img`
  width: 500px;
`;

export const BottomFixedBtn = styled.div`
  position: relative;
  
  display: flex;
  z-index: 13;
  bottom: 32px;
  
  justify-content: center;
  
  // border-radius: 24px;
  // flex-direction: row;
  // //border: 1px solid black;
  //   //background-color: ${purpleTextColor};
  // padding: 8px 12px;
  // background-color: white;
  //box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* 입체감 있는 그림자 */
`
export const GradationBtn = styled.button`
  position: relative;
  border-radius: 10px;
  z-index: 100;
  width: 150px;
  height: 50px;
  background: linear-gradient(135deg, ${purpleTextColor}, #a06cf1); /* 그라데이션 배경 */
  color: #fff; /* 텍스트 색상을 흰색으로 설정 */
  padding: 10px 20px; /* 상하좌우 패딩 설정 */
  border: none; /* 테두리 없애기 */
  font-size: 1rem; /* 글씨 크기 설정 */
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* props로 받은 shadowAlpha 적용 */
  transition: all 0.3s ease; /* 부드러운 애니메이션 효과 */
  
  align-self: center;
  &:hover {
    background: linear-gradient(135deg, #a06cf1, ${purpleTextColor}); /* 호버 시 그라데이션 반전 */
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5); /* 호버 시 그림자 더 강하게 */
    transform: translateY(-3px); /* 살짝 위로 올라가는 애니메이션 */
  }

  &:active {
    background: linear-gradient(135deg, #8a4de0, ${purpleTextColor}); /* 클릭 시 더 진한 색상 */
    transform: translateY(0px); /* 클릭 시 원래 위치로 돌아옴 */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* 클릭 시 그림자 복원 */
  }
`;

export const BlackBtn = styled.button`
  position: relative;
  border-radius: 10px;
  z-index: 100;
  width: 150px;
  height: 50px;
  background: black;
  color: #fff; /* 텍스트 색상을 흰색으로 설정 */
  padding: 10px 20px; /* 상하좌우 패딩 설정 */
  border: none; /* 테두리 없애기 */
  font-size: 1rem; /* 글씨 크기 설정 */
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* props로 받은 shadowAlpha 적용 */
  transition: all 0.3s ease; /* 부드러운 애니메이션 효과 */
  
  align-self: center;
  &:hover {
    background: white;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5); /* 호버 시 그림자 더 강하게 */
    transform: translateY(-3px); /* 살짝 위로 올라가는 애니메이션 */
  }

  &:active {
    background: linear-gradient(135deg, #8a4de0, ${purpleTextColor}); /* 클릭 시 더 진한 색상 */
    transform: translateY(0px); /* 클릭 시 원래 위치로 돌아옴 */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5); /* 클릭 시 그림자 복원 */
  }
`;

