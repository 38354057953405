// Text Color
export const boldTextColor = '#131313';
export const purpleTextColor = '#7d60e8';
export const boldGreyColor = '#6d6c6d';
export const lightBlueTextColor = '#6b869c';

// Button Color
export const lightGreyColor = '#d3d1d7';
export const boldGreyBtnColor = '#787878';
export const lightPurpleButtonColor = '#EDE4FF';
export const lightRedButtonColor = '#fa8e8e';

// Background Color
export const defaultBackgroundColor = '#ffffff'; // Colors.white에 해당
export const defaultContainerColor = '#fdfdfd';
export const lightCreamContainerColor = '#fcf4e3';
export const creamContainerColor = '#d7bc6f';
