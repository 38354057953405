import styled from "styled-components";
import {boldTextColor, purpleTextColor} from "../../utils/colors";

export const AppBarWrapper = styled.div`
  position: fixed; /* 화면 상단에 고정 */
  top: 0; /* 상단에 위치 */
  left: 0; /* 왼쪽에 위치 */
  flex-direction: row;
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-between;
  z-index: 20;
  background-color: white;
  border-bottom: 0.5px solid grey;
`;

export const TextBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const LogoImg = styled.img`
  margin-left: 16px;
  height: 70px;
  align-items: center;
  align-self: center;
`;

export const AppBarContainer = styled.div`
  position: fixed;
  z-index: 20;
`


type ButtonProps = {
    fontColor: string;
    centered?: boolean;
    isActive?: boolean; // 활성화 상태를 받는 prop 추가
};

export const AppBarSectionBtn = styled.button<ButtonProps>`
  font-size: 18px;
  color: ${(props) => (props.isActive ? "#6200ea" : props.fontColor)};  /* 활성화 상태일 때 색상 변경 */
  font-family: "Pretendard-Bold";
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px 20px;  /* 기본 패딩 */
  text-align: center;
  border-radius: 8px;  /* 기본적으로 모서리를 살짝 둥글게 */

  ${(props) =>
          props.centered &&
          `
    align-self: center;
  `};

  /* 기본 상태 */
  transition: all 0.3s ease-in-out;  /* 애니메이션을 부드럽게 */

  &:hover {
    background-color: #f0f0f0;  /* 밝은 회색 배경 */
    transform: scale(1.15);  /* 약간 확대 */
    border-radius: 12px;  /* hover 시 모서리를 더 둥글게 */
  }

  /* 반응형 디자인 */
  @media (max-width: 820px) {  /* 태블릿 이하 */
    font-size: 16px;  /* 폰트 크기 조정 */
    padding: 8px 16px;  /* 패딩 조정 */
  }
  
  /* 반응형 디자인 */
  @media (max-width: 754px) {  /* 태블릿 이하 */
    font-size: 14px;  /* 폰트 크기 조정 */
    padding: 8px 16px;  /* 패딩 조정 */
  }
  
  @media (max-width: 720px) {  /* 모바일 */
    display:  none;
  }
`;
